import { DEFAULT_LANGUAGE } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = true;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Yohana Fransiska Kanisia Hadiati';
export const GIRL_NAME_SHORT = 'Yohana';
export const GIRL_FATHER_NAME = `Brigjen (Purn) Drs. Ignasius Wahyu Hadi Prasetyo Tomo`;
export const GIRL_MOTHER_NAME = `Asteria Dewi Utami`;
export const GIRL_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `<b>The daughter of</b><br /> Mr. ${GIRL_FATHER_NAME} <br /> and Mrs. ${GIRL_MOTHER_NAME}`
    : `<b>Putri dari</b> <br />Bapak ${GIRL_FATHER_NAME} <br /> dan Ibu ${GIRL_MOTHER_NAME}`
  ;

// -> boy section
export const BOY_NAME = 'Kristofer Anandita';
export const BOY_NAME_SHORT = 'Kristofer';
export const BOY_FATHER_NAME = `Ir. Aditya Adinatha, MBA.`;
export const BOY_MOTHER_NAME = `drg. Marta Juslily, MBA., MKG`;
export const BOY_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `<b>The son of</b><br /> Mr. ${BOY_FATHER_NAME} <br /> and Mrs. ${BOY_MOTHER_NAME}`
    : `<b>Putra dari</b><br /> Bapak ${BOY_FATHER_NAME}<br /> dan Ibu ${BOY_MOTHER_NAME}`
  ;

// -> bride section
export const BRIDE_HASHTAG = ``;
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'kitspresso';
export const IG_GIRL = 'yohanakanisia';
export const IG_FILTER_URL = ``;

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/josantoso/kristofer-yohana/seo.jpg?updatedAt=1703745003651`;
export const SEO_URL = 'https://invitato.net/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  DEFAULT_LANGUAGE === 'en'
    ? `Together with joyful hearts, we re pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information details.`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1705834800;
export const EPOCH_RESEPSI_START = 1705834800;
export const EPOCH_END_EVENT = 1705842000;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2024-01-21');
export const DATE_RESEPSI = new Date('2024-01-21');

export const WEDDING_AKAD_TIME = '10.00 WIB';
export const WEDDING_AKAD_LOC_NAME = 'Gereja Katedral Jakarta';
export const WEDDING_AKAD_LOC_ROAD = `Jl. Katedral No. 7B, Ps. Baru, Kec. Sawah Besar, Kota Jakarta Pusat, DKI Jakarta 10710`;
export const WEDDING_AKAD_DRESSCODE = ``;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
.locale(DEFAULT_LANGUAGE)
.format('dddd, DD MMMM YYYY');
// in case you need custom format for full date, just uncomment below section
// export const WEDDING_AKAD_FULLDATE = `Saturday, June 18<sup>th</sup>, 2022`;

export const WEDDING_RESEPSI_TIME = '18.00 WIB';
export const WEDDING_RESEPSI_LOC_NAME = 'Grand Ballroom, Fairmont Hotel';
export const WEDDING_RESEPSI_LOC_ROAD = `Jl. Asia Afrika No. 8, Senayan, Kec. Tanah Abang, Kota Jakarta Pusat, DKI Jakarta 10270`;
export const WEDDING_RESEPSI_DRESSCODE = `International/Modern`;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
.locale(DEFAULT_LANGUAGE)
.format('dddd, DD MMMM YYYY');
// in case you need custom format for full date, just uncomment below section
// export const WEDDING_RESEPSI_FULLDATE = `Saturday, June 18<sup>th</sup>, 2022`;
export const WEDDING_RESEPSI_DATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('DD • MMMM • YYYY');
export const IS_SAME_LOCATION =
  WEDDING_AKAD_LOC_NAME === WEDDING_RESEPSI_LOC_NAME &&
  WEDDING_AKAD_LOC_ROAD === WEDDING_RESEPSI_LOC_ROAD;

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://kristoferyohana.com/';
export const BROADCAST_WEDDING_LOCATION = `Grand Ballroom, Fairmont Hotel, Jakarta Pusat`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_AKAD = ``;
export const GOOGLE_MAPS_RESEPSI = `https://maps.app.goo.gl/HyMugNSMn2QGEzai8`;
export const GOOGLE_MAPS_ADDRESS = `Fairmont Jakarta`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Mac Miller - Congratulations (instrumental)`;
export const SOUND_URL = 'https://youtu.be/AO5Rz-Uw6Qg?si=WTPmCgW_msn9Y4Bv';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/gp9uomY7k-Q';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/gp9uomY7k-Q';
export const PREWEDDING_LINK = 'https://youtu.be/gp9uomY7k-Q';
export const PREWEDDING_EMBED = 'https://www.youtube.com/embed/gp9uomY7k-Q';

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbzj3m9xy9QFjpcnpQDfJyyqKZTsY9BY_BIjE8etY_csxNUSSxJFQv1_0L0rZHCBUlkYDw/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const INVITATO_URL = 'https://invitato.id';
export const INVITATO_IG_URL = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO = 'https://wa.me/+628112778189?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';