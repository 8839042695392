import { VENDOR_LISTS } from './vendor-collaboration';

// invitation flag feature
export const ENABLE_SHIFT_TIME_RECEPTION = true;
export const ENABLE_ANNOUNCEMENT_MODE = true;
export const ENABLE_PARTNER_MODE = true;
export const ENABLE_GUEST_PERSONALIZATION = true;

// Giftcard flag feature
export const ENABLE_SEND_GIFT = false;
export const ENABLE_BANK_TRANSFER = true;
export const ENABLE_GIFT_CONFIRMATION = true;

// generator link flag feature
export const ENABLE_GUEST_LIST = true;
export const ENABLE_CUSTOM_LINK = true;

// QRCODE feature flags
export const DISABLED_QR_GENERATOR_IN_PRODUCTION = true;
export const ENABLE_QR_NAME_VALIDATION = true;
export const ENABLE_QR_INVITATION = false;

// Special feature
export const DEFAULT_LANGUAGE = 'en'; // change to `en` if you want to set default to English
export const ENABLE_MULTI_LANGUAGE = false;
export const ENABLE_MUSLIM_LANGUAGE = false; // change to `true` if want to use muslim version
export const ENABLE_AYAT_ALTERNATIVE_POSITION = true; // change to `true` if live streaming is disabled
export const DISABLED_MUSIC_IN_DEVELOPMENT = true;
export const ENABLE_MUSIC_BACKSOUND = true;
export const ENABLE_DARKMODE_NOTIFICATION = true;
export const ENABLE_COLLABORATION = false; // change to `true` if any collaboration with Invitato partner
export const SELECTED_VENDOR_COLLABORATION = VENDOR_LISTS['aksara']; // change 'aksara' to any of the vendor inside VENDOR_LISTS if want to use a new vendor, just copy and add under the last vendor
export const ENABLE_GUEST_INFORMATION = false;
export const ENABLE_BLACK_AND_WHITE = false;

// Global feature invitation
export const ENABLE_LIVE_STREAMING = false;
export const ENABLE_VIDEO_PREWEDDING = false; // change to `true` if you want add prewedding video
export const ENABLE_GIFT_CARD = true;
export const ENABLE_LOVE_STORY = true;
export const ENABLE_RSVP = true;
export const ENABLE_COVID_POPUP = false;
export const ENABLE_IG_FILTER = false;
export const ENABLE_DUAL_PHOTO = false;